$(document).foundation();

/***************************************************
 * cookie warning modal
 ***************************************************/

var cookieWarning = function() {
	if($.cookie('cookie_warning') == undefined) {
		$('#cookieWarning').addClass('active');
	}
	$('#cookieWarning .button').on('click', function() {
		$('#cookieWarning').hide();
		$.cookie('cookie_warning', true, { expires: 365, path: '/' });
	});
}
cookieWarning();

/***************************************************
 * change product link on hover
 ***************************************************/

var productSeoToUser = function() {
	var productImages = $(document).find('.product-image-wrapper');
	$.each(productImages, function(index){
		var $this = $(this);
		if($this.data('image') !== undefined) {
			$this.append('<img src="' + $this.data('image') + '" />');
			$this.addClass('ready');
		}
		if($this.data('class') !== undefined) {
			$this.addClass($this.data('class'));
		}
	});
	
	$(document).find('.product-detail-link').hide();
	
	var productExternalLinkArray = $(document).find('.product-external-link');
	$.each(productExternalLinkArray, function() {
		$(this).mouseenter(function() {
			var href = $(this).attr('href');
			var link = $(this).data('link');
			$(this).attr('href', link).data('link', href);
		})
		.mouseleave(function() {
			var href = $(this).attr('href');
			var link = $(this).data('link');
			$(this).attr('href', link).data('link', href);
		});
		$(this).on('click', function() {
			if(typeof ga !== 'undefined') ga('send', 'event', 'Products', 'Open', $(this).data('product_id'));
		});
	});
	
}
productSeoToUser();


/***************************************************
 * copy current query text to search form field
 ***************************************************/

var copyQueryText = function($el) {
	$('#formInput').val($el.text());
	$('#formInputMobile').val($el.text());
}
if($('#queryText').length > 0) {
	copyQueryText($('#queryText'));
}

/***************************************************
 * sidebar list search input
 ***************************************************/

var sidebarList = function($item) {
	var $input = $item.siblings('.list-search');

	$input.on('change keyup', function() {
		$.each($item.find('li'), function() {
			if($(this).children('a').text().toLowerCase().indexOf($input.val().toLowerCase()) == '-1') {
				$(this).hide();
			} else {
				$(this).show();
			}
		});
	});
}


/***************************************************
 * load sidebar
 ***************************************************/

var loadSidebar = function($sidebar) {
	$.ajax({
		url: 'sidebar/'
	}).success(function(response) {
		$sidebar.html(response);
		$sidebar.foundation();
		
		$.each($sidebar.find('ul.list'), function() {
			sidebarList($(this));
		});

		var $el = $sidebar,
			slider = $el.find('#rangeSlider')[0],
			low = Number($(slider).data('low')),
			high = Number($(slider).data('high')),
			$lowSpan = $el.find('.low'),
			$highSpan = $el.find('.high'),
			$btn = $el.find('.button');

		var formatEuro = function(el) {
			return el.replace(',', '').split('.').join(',');
		}

		noUiSlider.create(slider, {
			start: [low, high],
			connect: true,
			step: 1,
			range: {
				'min': low,
				'max': high
			}
		});
		
		slider.noUiSlider.on('update', function( values, handle ) {
			low = values[0];
			high = values[1];

			if ( handle ) {
				$highSpan.val(formatEuro(values[handle]));
			} else {
				$lowSpan.val(formatEuro(values[handle]));
			}
		});
	});
}
if($('.ajaxSidebar').length > 0) {
	loadSidebar($('.ajaxSidebar'));
};
